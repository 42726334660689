"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ignoreWarnings;

var _newregexp = _interopRequireDefault(require("newregexp"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ignoreWarnings(type, ignoreMessages) {
  if (!ignoreMessages) {
    ignoreMessages = type;
    type = 'warn';
  }

  if (!Array.isArray(ignoreMessages)) ignoreMessages = [ignoreMessages];
  var overloadedConsole = {
    log: console.log,
    info: console.info,
    warn: console.warn,
    error: console.error
  };

  console[type] = function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var log = true;
    ignoreMessages.forEach(function (ignoreMessage) {
      var message = args.join(' ').slice(0, -1);

      if (/^\/.*\/[a-z]*$/.test(ignoreMessage)) {
        var regex = (0, _newregexp.default)(ignoreMessage);
        if (regex.test(message)) log = false;
      } else if (message.indexOf(ignoreMessage) > -1) {
        log = false;
      }

      if (!log) return false;
      return true;
    });
    if (log) overloadedConsole[type].apply(overloadedConsole, args);
  };
}